import { useEffect, useRef, useState } from 'react';
import { useMenuHeight } from '../../../hooks';
import { Button } from '@components/tailwind';
import cx from '@utils/cx.util';

type Section = {
  title: string;
  href: string;
};

const sections: Section[] = [
  {
    title: 'Getting Started',
    href: '#gettingStarted',
  },
  {
    title: 'What We Do',
    href: '#whatWeDo',
  },
  {
    title: 'Get In Touch With Us',
    href: '#getInTouchWithUs',
  },
];

export const CustomizationSectionNav = () => {
  const { mainMenuHeight } = useMenuHeight();
  const navRef = useRef<HTMLDivElement>(null);
  const [shouldShowStartCustomizing, setShouldShowStartCustomizing] = useState(false);

  useEffect(() => {
    const startCustomizingButton = document.getElementById('startCustomizingButton');

    if (!startCustomizingButton) return;

    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      setShouldShowStartCustomizing(!entries[0].isIntersecting);
    };

    const observer = new IntersectionObserver(observerCallback, {
      rootMargin: `-${mainMenuHeight + 80}px 0px 0px 0px`,
    });

    observer.observe(startCustomizingButton);

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleSectionClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, section: Section) => {
    event.preventDefault();
    const element = document.getElementById(section.href.split('#')[1]);
    if (element) {
      window.scrollTo({
        top: element.offsetTop,
        behavior: 'smooth',
      });
    }
    window.history.pushState(null, '', section.href);
  };

  return (
    <div
      ref={navRef}
      style={{ top: mainMenuHeight }}
      className='tw-sticky tw-z-20 tw-border-b tw-border-b-neutral-300 tw-bg-white'>
      <div
        className={cx(
          'scrollbar-hide tw-mx-auto tw-flex tw-w-auto tw-items-center tw-gap-1 tw-overflow-x-auto tw-bg-white tw-px-6 tw-text-brand-black md:tw-justify-center md:tw-px-0',
          {
            'tw-justify-center': !shouldShowStartCustomizing,
          }
        )}>
        {shouldShowStartCustomizing && (
          <div className='tw-w-auto tw-shrink-0 tw-animate-[fadeIn_0.5s] tw-py-1'>
            <Button
              variant='primary'
              size='xs'
              href='/custom/prices'
              className='tw-px-2 tw-py-1 tw-text-2xs md:tw-px-3 md:tw-py-2 md:tw-text-xs'>
              Start Customizing
            </Button>
          </div>
        )}
        {sections.map((section, index) => (
          <a
            key={index}
            href={section.href}
            onClick={(event) => {
              handleSectionClick(event, section);
            }}
            className='tw-w-auto tw-shrink-0 tw-p-3 tw-text-xs tw-font-medium tw-text-brand-black hover:tw-text-brand-black md:tw-p-5 md:tw-text-[15px]'>
            {section.title}
          </a>
        ))}
      </div>
    </div>
  );
};
